import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { dataChristian } from '../hooks/dataPersons';

export default function Impressum() {
	return (
		<Layout className="markdown">
			<SEO keywords={[ `Physiotherapie`, `Wien`, `1010`,  `movendos`, `Manualtherapie`, `Manuelle Therapie` ]} title="Home" />
			<h1 className="text-xl text-primary">Impressum</h1>
			<p className="font-medium">Movendos</p>
			<p>
				{dataChristian.name} <br /> Rueppgasse 3/11 <br /> 1020 Wien <br /> {dataChristian.phone} <br />
				{dataChristian.email}
			</p> <br />
			Fotos: Alexander Soukup <br /> Webdesign: Pietro Palmesi &amp; Julia Slechta-Pleterski
		</Layout>
	);
}
